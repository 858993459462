import "./jquery.visible.min";
var $window;
var $navbar;
var $header;
var $navbarheight = 160;
var $navbarminheight = 50;
var $navbarsubtop = 80;
var $navbarsubtopmin = 78;
var $logoheight = 97;
var $logominheight = 45;
var $logo;
var $navbarsub;
var $navbarshadow;


$(document).ready(function() {
    $window = $(window);
    $header = $('nav');
    $navbarshadow = $('.header--shadow');
    $navbar = $header.find('.header');
    $navbarsub = $header.find('.dropdown-menu');
    $logo = $header.find('.logo');
    initContent();
});

function initContent() {
    animateContent();
    $window.on('scroll', function (e) {
        animateScrollSlower(e);
        if($(window).width() > 1199) {
            animateMenue(e);
        } else {
            animateMenueMobil(e);
        }
    });

    if($(window).width() < 575) {
        $('.kartenkategorie').on('click', function() {
           if(parseInt($(this).css('max-height')) <= 50) {
               $(this).css('max-height', '15000px');
           } else {
               $(this).css('max-height', '30px');
           }
        });
    }

    initTeaserSlider(true);
    initBilderslider(true);

    $('.dropdown-toggle').on('click', function() {
        var obj = $(this).next();
        obj.css('opacity', 0);
        obj.css('display: block');
        setTimeout(function() {
            obj.css('transition', 'opacity 0.3s');
            obj.css('opacity', 1);
        }, 50);
    });

    parallax();
    $(window).on('scroll', function() {
        parallax();
    });

    var iconname = '';
    var pfad = '';
    $('.fontawsome-icon').each(function() {
       iconname = $(this).data('iconname');
       pfad = $(this).children().attr('src');
       $(this).load(pfad);
    });
}

function animateContent() {
    $('.ani-fade').each( function() {
       $(this).addClass('ani-fade-in');
    });
}

function animateScrollSlower(e) {
    var scrollhoehe = e.currentTarget.scrollY / 3;

    $('.slowerscroll').css('transform','translateY(+'+scrollhoehe+'px)');
}

function animateMenue(e) {
    var scrollhoehe = e.currentTarget.scrollY;

    var paramter = 160;

    var socialtop = (-165) + scrollhoehe / 4;
    var navtop = paramter - scrollhoehe / 6;
    var logotop = 0 - scrollhoehe / 4;
    if (navtop < 50)
        navtop = 50;
    if (socialtop > -55)
        socialtop = -55;

    if (socialtop > -93) {
        $('.socialmediaicons').addClass('text-light');
        $('.nextdate').css('opacity', 1);
        $('.nextdate').css('mix-blend-mode', 'unset');
        $('.nextdate').css('color', '#ffffff');
        $('.navbar').addClass('navbar-dark').removeClass('navbar-light');
    } else {
        $('.socialmediaicons').removeClass('text-light');
        $('.navbar').addClass('navbar-light').removeClass('navbar-dark');
        $('.nextdate').css('opacity', 0.5);
        $('.nextdate').css('mix-blend-mode', 'color-dodge');
        $('.nextdate').css('color', '#cccccc');
    }


    // $('.socialmediaicons').css('transform','translate3d(0px, ' + socialtop + 'px, 0px)');
    $('.socialmediaicons').css('top', socialtop);
    $('.navbar').css('top', navtop);
    $('.logo-brauhaus').css('top', logotop);

    var navbarTranslateTop = scrollhoehe / 6;
    var navheighttmp = $navbarheight - navbarTranslateTop;
    if (navheighttmp < $navbarminheight) navheighttmp = $navbarminheight;


    $navbar.css('height', navheighttmp + 'px');
    $navbarshadow.css('top', navheighttmp + 'px');

    var navsubtoptmp = $navbarsubtop - (scrollhoehe / 8);
    if (navsubtoptmp < $navbarsubtopmin) navsubtoptmp = $navbarsubtopmin;

    $navbarsub.css('top', navsubtoptmp + 'px');


    var logoheighttmp = $logoheight - (scrollhoehe / 4);
    if (logoheighttmp < $logominheight) logoheighttmp = $logominheight;

    $logo.css('height', logoheighttmp + 'px');
}

function animateMenueMobil(e) {
    var scrollhoehe = e.currentTarget.scrollY;

    var logotop = 0 - scrollhoehe * 2;

    var iconsopacity = (scrollhoehe / 2) * 0.08;

    if(iconsopacity > 1) {
        if(iconsopacity > 30) {
            iconsopacity = 0;
            $('.nextdate').css('opacity', 1);
            $('.nextdate').css('display', 'block');
            $('.navbar>.socialmediaicons').css('opacity', 0);
        } else {
            iconsopacity = 1;
            $('.nextdate').css('opacity', 0);
        }
    }


    $('.navbar>.socialmediaicons').css('opacity', iconsopacity);


    // $('.socialmediaicons').css('transform','translate3d(0px, ' + socialtop + 'px, 0px)');
    if(logotop < 100)
        $('.logo-brauhaus').css('top', logotop);
}


function initTeaserSlider(start = false) {
    if(start !== true)
    $('.teaser').each(function(index) {
        var tmp = $(this);
        var activeIndex = 0;
        var fertig = false;
        if($('.teaser').visible(true)) {
            tmp.children('.teaser--bg').each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            })
        }
    });
    setTimeout(function() {
        initTeaserSlider();
    }, 5000);
}

var wasClicked = false;
function initBilderslider(first = false) {
    if(wasClicked !== false)
        return false;

    if(first === true) {
        $('.bilder--slider').children().each(function(index) {
            $(this).data('picindex', index);
            if(index == 0)
                $(this).addClass('active');
        });
        $('.bilder-slider-preview').children().each(function(index) {
            $(this).data('picindex', index);
            if(index == 0)
                $(this).addClass('active');
        });
        $('.bilder-slider-preview').children().addClass('bildersliderAnker');

        $('.bildersliderAnker').off('click').on('click', function() {
            first = true;
            var clicked = $(this).data('picindex');
            $('.bilder--slider').children().each(function() {
                if($(this).data('picindex') == clicked) {
                    $('.bilder--slider>.active').removeClass('active');
                    $(this).addClass('active');
                }
            });
            $('.bilder-slider-preview').children().each(function() {
                if($(this).data('picindex') == clicked) {
                    $('.bilder-slider-preview>.active').removeClass('active');
                    $(this).addClass('active');
                }
            });
            wasClicked = true;
        });
    }

    if(first !== true && $('.bilder--slider').visible(true)) {
        $('.bilder--slider').each(function (index) {
            var obj = $(this);
            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
        $('.bilder-slider-preview').each(function (index) {
            var obj = $(this);

            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
    }

    setTimeout(function() {
        if(wasClicked === false) {
            initBilderslider(false);
        } else {
            wasClicked = false;
            setTimeout(function() {
                initBilderslider(false);
            }, 3000);
        }
    }, 3000);
}

function parallax() {
    $('.parallax').each(function() {
        var $elem = $(this);

        if($elem.visible(true)) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 3);

            if ($elem.parent().hasClass('teaser--halb')) {
                // oVal = ((window_bottom - parent_top) / 3);
            }

            var tmp = parseInt($elem.height()) * -1;
            if(tmp > oVal)
                oVal = tmp;

            $elem.children().css('transform', 'translate3d(0px, ' + oVal + 'px, 0px)');
        }
    });

    $('.parallaxslower').each(function() {
        var $elem = $(this);

        if($elem.visible(true)) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 12);

            if ($elem.parent().hasClass('teaser--halb')) {
                // oVal = ((window_bottom - parent_top) / 3);
            }

            var tmp = parseInt($elem.height()) * -1;
            if(tmp > oVal)
                oVal = tmp;

            $elem.children().css('transform', 'translate3d(0px, ' + oVal + 'px, 0px)');
        }
    });

    $('.parallax-horizontal').each(function() {
        var $elem = $(this);

        if($elem.visible(true)) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 15);

            $elem.children().css('transform', 'translate3d(' + oVal + 'px, 0px, 0px)');
        }
    });
}